import { Banner, BannerImage } from '@faststore/ui'
import { stripUrl } from 'src/utils/stripUrl'

import './styles.scss'

import AppPageBottom from './AppPageBottom'
import { Image } from '../ui/Image'

const AppPageMiddle = () => (
  <div className="app-page_container w-full">
    <div className="app-page_content w-full flex  flex-col items-center justify-center ">
      <section className="app-page_section-middle w-full md:grid md:grid-cols-2">
        <Banner>
          <BannerImage>
            <Image
              className="md:w-96 md:h-[580px] mt-10 mb-10 mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/base-cel-image.png'
              )}
              alt=""
              loading="lazy"
              width={256}
              height={370}
            />
          </BannerImage>
        </Banner>
        <div className="md:flex md:items-center md:justify-center md:flex-col md:w-2/4">
          <h1 className="text-white font-semibold italic m-0 text-center mt-4">
            A QUALQUER HORA, EM QUALQUER LUGAR
          </h1>
          <hr className="w-6/12 mx-auto my-0 mt-2" />
          <p className="text-white text-center mt-4 text-2xl m-2">
            Compre de forma simples, rápida e segura a linha completa de
            produtos Decathlon.
          </p>
          <p className="text-white font-bold text-center mt-4 text-2xl">
            Tudo isso na palma da sua mão!
          </p>
        </div>
      </section>
      <AppPageBottom />
    </div>
  </div>
)

export default AppPageMiddle
