import { BannerImage, Button } from '@faststore/ui'
import { stripUrl } from 'src/utils/stripUrl'

import './styles.scss'

import { Image } from '../ui/Image'

const AppPageBottom = () => (
  <div className="howToUse">
    <section className="howToUse--container">
      <h1 className="text-blue text-center font-bold italic mt-20 text-2xl md:text-4xl">
        VEJA COMO É FÁCIL UTILIZAR O APP <br />
        DECATHLON:
      </h1>
      <div className="howToUse--contentSale">
        <div className="howToUse--contentSale_description">
          <BannerImage className="howToUse--contentSale_icon">
            <Image
              className="mt-10 mb-10 mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/icon-utilizar-new-app-1.png'
              )}
              alt="utilizar new app"
              loading="lazy"
              width={90}
              height={90}
            />
          </BannerImage>
          <p className="howToUse--contentSale_descriptionText">
            Faça download do aplicativo na Play Store ou App Store
          </p>
        </div>

        <BannerImage className="howToUse--arrowIcon md:hidden">
          <Image
            className="mt-10 mb-10 mx-auto my-0"
            src={stripUrl(
              'https://decathlonproqa.vteximg.com.br/arquivos/arrow-icon-utilizar-new-app.png'
            )}
            alt="utilizar new app"
            loading="lazy"
            width={65}
            height={50}
          />
        </BannerImage>
        <div className="howToUse--contentSale_description">
          <BannerImage className="howToUse--contentSale_icon">
            <Image
              className="mt-10 mb-10  mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/icon-utilizar-new-app-2.png'
              )}
              alt=""
              loading="lazy"
              width={90}
              height={90}
            />
          </BannerImage>
          <p className="howToUse--contentSale_descriptionText">
            Encontre os produtos para você e adicione no seu carrinho
          </p>
        </div>

        <BannerImage className="howToUse--arrowIcon hidden md:flex">
          <Image
            className="mt-10 mb-10  mx-auto my-0"
            src={stripUrl(
              'https://decathlonproqa.vteximg.com.br/arquivos/arrow-icon-utilizar-new-app.png'
            )}
            alt=""
            loading="lazy"
            width={65}
            height={50}
          />
        </BannerImage>
        <div className="howToUse--contentSale_description">
          <BannerImage className="howToUse--contentSale_icon">
            <Image
              className="mt-10 mb-10  mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/icon-utilizar-new-app-3.png'
              )}
              alt=""
              loading="lazy"
              width={90}
              height={90}
            />
          </BannerImage>
          <p className="howToUse--contentSale_descriptionText">
            Escolha a melhor forma de pagamento e o método de envio
          </p>
        </div>

        <BannerImage className="howToUse--arrowIcon hidden md:flex">
          <Image
            className="mt-10 mb-10  mx-auto my-0"
            src={stripUrl(
              'https://decathlonproqa.vteximg.com.br/arquivos/arrow-icon-utilizar-new-app.png'
            )}
            alt=""
            loading="lazy"
            width={65}
            height={50}
          />
        </BannerImage>
        <div className="howToUse--contentSale_description">
          <BannerImage className="howToUse--contentSale_icon">
            <Image
              className="mt-10 mb-10  mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/icon-utilizar-new-app-4.png'
              )}
              alt=""
              loading="lazy"
              width={90}
              height={90}
            />
          </BannerImage>
          <p className="howToUse--contentSale_descriptionText">
            Finalize seu pedido e aguarde a sua encomenda
          </p>
        </div>
      </div>
      <Button className="howToUse--button font-semibold flex justify-center mx-auto my-0 mb-10 h-10 mt-10">
        BAIXE AGORA
      </Button>
    </section>
  </div>
)

export default AppPageBottom
