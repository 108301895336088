import { BannerImage, Button } from '@faststore/ui'
import { stripUrl } from 'src/utils/stripUrl'

import './styles.scss'

import AppPageMiddle from './AppPageMiddle'
import { Image } from '../ui/Image'

const AppPage = () => (
  <div className="app-page_container w-full">
    <div className="app-page_content w-full flex  flex-col items-center justify-center">
      <section className="app-page_section-banner w-full ">
        <div className="app-page_section-grid flex flex-col-1 flex-col-reverse md:grid md:grid-cols-2">
          <div className="app-page_col-1-text flex justify-center items-center flex-col">
            <h1 className="text-3xl md:text-4xl text-white italic text-center m-0 font-semibold">
              CONHEÇA O
            </h1>
            <span className="text-5xl md:text-6xl text-white italic font-bold">
              APP DECATHLON
            </span>
            <h2 className="text-3xl md:text-4xl text-white italic text-center m-0 font-semibold">
              65 ESPORTES NA SUA MÃO!
            </h2>
            <hr className="w-6/12" />
            <p className="p-info">
              Baixe o <strong>App Decathlon</strong> e tenha
              <br /> acesso a <strong>+ de 7 mil produtos </strong>
              <br />
              exclusivos!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
              <div className="qr-col-1 flex justify-center mb-2">
                <BannerImage>
                  <Image
                    src={stripUrl(
                      'https://decathlonproqa.vteximg.com.br/arquivos/qr-code-app.png'
                    )}
                    alt="qr code app"
                    loading="lazy"
                    width={175}
                    height={175}
                  />
                </BannerImage>
              </div>
              <div className="upload-app flex flex-col justify-center">
                <BannerImage>
                  <Image
                    className="mb-2"
                    src={stripUrl(
                      'https://decathlonproqa.vteximg.com.br/arquivos/new-app-play-store.png'
                    )}
                    alt="new app play store"
                    loading="lazy"
                    width={240}
                    height={66}
                  />
                </BannerImage>

                <BannerImage>
                  <Image
                    className="mb-10 md:mb-0"
                    src={stripUrl(
                      'https://decathlonproqa.vteximg.com.br/arquivos/new-app-apple-store.png'
                    )}
                    alt="new app apple store"
                    loading="lazy"
                    width={240}
                    height={66}
                  />
                </BannerImage>
              </div>
            </div>
          </div>

          <div className="app-page_col-2-banner">
            <BannerImage>
              <Image
                className="p-10 md:p-0 md:w-2/4 md:h-[680px] mx-auto my-0 "
                src={stripUrl(
                  'https://decathlonproqa.vteximg.com.br/arquivos/app-cell.png'
                )}
                alt="app cell"
                loading="lazy"
                width={256}
                height={500}
              />
            </BannerImage>
          </div>
        </div>
      </section>
      <section className="app-page_section-banner-2 w-full">
        <div>
          <p className="text-2xl md:text-4xl text-blue text-center italic font-bold mt-10 p-4">
            AGORA OS PRODUTOS DECATHLON ESTÃO AINDA MAIS PERTO DE VOCÊ!
          </p>
          <BannerImage>
            <Image
              className="mt-10 md:p-0 md:w-66 md:h-[150px] mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/discount-app.png'
              )}
              alt="discount app"
              loading="lazy"
              width={384}
              height={125}
            />
          </BannerImage>

          <BannerImage>
            <Image
              className="w-[600px] h-[130px] md:w-66 md:h-[180px] mt-10 mb-10  mx-auto my-0"
              src={stripUrl(
                'https://decathlonproqa.vteximg.com.br/arquivos/horizontal-cell.png'
              )}
              alt="horizontal cell"
              loading="lazy"
              width={600}
              height={130}
            />
          </BannerImage>
          <Button className="howToUse--button font-semibold flex justify-center mx-auto my-0 mb-10">
            BAIXE AGORA
          </Button>
        </div>
      </section>
      <AppPageMiddle />
    </div>
  </div>
)

export default AppPage
